<template>
  <div>
    <h3>Påstand:</h3>
    <h2 class="paastander">UiA skal jobbe for at flere drar på utveksling.</h2> <!-- Expression/Question -->
    <vsa-list>
      <vsa-item>
        <vsa-heading>
          Les mer her
        </vsa-heading>

        <vsa-content>
          <div class="explaination">
            <p>
              UiA har som mål å oppnå 50 % utveksling. I forbindelse med det målet så er det blitt foreslått
              forskjellige tiltak for å øke studentutvekslingen. Er dette noe UiA bør ha mer fokus på?
            </p>
          </div>
        </vsa-content>
      </vsa-item>
    </vsa-list>
    <slot>></slot> <!-- Slot for react buttons -->
  </div>

</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
  name: "Spm1",
  components:{
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  }
}
</script>

<style scoped>

</style>